import React from 'react';
import classnames from 'classnames';
import * as styles from './Card.module.scss';

import { navigate } from 'gatsby';

type Props = {
    children?: any;
    image?: any;
    className?: string;
    to?: string;
}

export default (props: Props) => {
    const {children, image, className, to } = props;

    const onClick = () => {
        if (to) {
            navigate(to);
        }
    }


    return <div onClick={onClick} className={classnames(["card", className, to ? styles.link : "" ])} >
        {image && <div className="card-image">
            {image}
        </div>}


        {children && <div className="card-content">
            {children}
        </div>}
    </div>
}