import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout";
import Hero from "../../components/ui/Hero/Hero";
import Section from "../../components/ui/Section/Section";
import Card from "../../components/ui/Card/Card";
import ButtonLink from "../../components/ui/Buttons/ButtonLink";
import Questions from "../../components/blocks/Questions";
import HorizontalScroll from "../../components/ui/HorizontalScroll/HorizontalScroll";
import FallbeispieleReferences from "../../components/blocks/FallbeispieleReferences";

import Leistungen1 from "../../assets/materialien.svg";
import Leistungen2 from "../../assets/druckverfahren.svg";
import Leistungen3 from "../../assets/weiterverarbeitung.svg";
import ScrollDownCircle from "../../components/ui/ScrollDownCircle/ScrollDownCircle";

export default ({pageContext, data}) =>{
    return (
        <Layout hasWave={false} headerColor="dark-blue" breadcrumbColor="dark" crumbs={[{ label: "Home", url: "" }, { label: "Leistungen", url: "leistungen" }]}>

            <Hero
                title="Unsere Leistungen"
                subtitle="Die Stärke ist die Kombination von Materialien, Druckverfahren und Weiterverarbeitung. Unser Know-How ermöglicht es Ihnen ihre Probleme zu lösen."
                color="dark-blue"
                colorDesktop="light"
                hasWaveBottom={["mobile", "tablet"]}
                backgroundImage={data.header.childImageSharp.gatsbyImageData}
            />

            <div className="container">
                <ScrollDownCircle id="produktzusammensetzung" indend={1}  afterWave={true} />
            </div>

            <Section id="produktzusammensetzung" hasTitle={true}>
                <div className="content content-small">
                    <h2 className="title">Produkt&shy;zusammen&shy;setzung</h2>
                    <p>Die richtige Kombination von Material, Druckverfahren und der Weiterverarbeitung trifft die gewünschte Anforderung. Wir bieten sehr viele verschiedene Verfahren an weshalb wir die Problemlöser für einfache bis hin zu den schwierigsten Anforderungen sind</p>
                </div>
                
                <HorizontalScroll>
                    <div className="columns has-flick-columns-tablet">
                        <div className="column">
                            <Card to="/leistungen/materialien">
                                <div className="content">
                                    <figure className="image is-128x128">
                                        <img src={Leistungen1} alt="Leistungen verschiedene Materialien" />
                                    </figure>
                                    <h3>Material</h3>
                                    <p>
                                        Grundbasis für unsere Produkte ist das Material. Bereits hier können verschiedene Eigenschaften ausgewählt werden.
                                    </p>
                                    <ButtonLink to="/leistungen/materialien">Mehr <span className="is-hidden-touch"> Informationen</span></ButtonLink>
                                </div>
                            </Card>
                        </div>
                        <div className="column">
                            <Card to="/leistungen/druckverfahren">
                            <div className="content">
                                    <figure className="image is-128x128">
                                        <img  src={Leistungen2} alt="Leistungen Druckverfahren" />
                                    </figure>
                                    <h3>Druck&shy;verfahren</h3>
                                    <p>
                                        Unterschiedliche Druckarten erlauben eine weite Spanne der Anforderungen abzudecken. Hier gilt es den Spagat zwischen Qualtität und Kosten zu ermitteln.
                                    </p>
                                    <ButtonLink to="/leistungen/druckverfahren">Mehr <span className="is-hidden-touch"> Informationen</span></ButtonLink>
                                </div>
                            </Card>
                        </div>
                        <div className="column">
                            <Card to="/leistungen/weiterverarbeitung">
                                <div className="content">
                                    <figure className="image is-128x128">
                                        <img  src={Leistungen3} alt="Leistungen Weiterverarbeitung" />
                                    </figure>
                                    <h3>Weiter&shy;verarbeitung</h3>
                                    <p>
                                        Bringt das Produkt in die passende Form, macht es haltbarer oder fügt Ihren Anforderungen entsprechend zusätzliche Eigenschaften hinzu.
                                    </p>
                                    <ButtonLink to="/leistungen/weiterverarbeitung">Mehr <span className="is-hidden-touch"> Informationen</span></ButtonLink>
                                </div>
                                
                            </Card>
                        </div>
                    </div>
                </HorizontalScroll>
                
            </Section>

            {/* <GraphicDesign /> */}


            {/* <Section hasTitle={true}>
                <div className="content">
                    <h2 className="title">Automatisie&shy;rung und Anbindung</h2>
                    <p>
                        Moderne Prozesse ersparen Arbeit und reduzieren Kosten. Wir bieten alle Schnittstellen von 
                        direkter Anbindung wie die Übermittlung von Auftrags- und Druckdaten bis hin zu partiellen 
                        Lösungen wie für unsere Kunden angepasste Web to Print Designer oder Kundenspeziefische 
                        Webshops um Bestellungen fehlerfrei und einfach auszuführen.
                        </p>
                </div>

                <HorizontalScroll>
                    <div className="columns has-flick-columns-tablet">
                        <div className="column">
                            <Card>
                                <div className="content">
                                    <figure className="image is-128x128">
                                        <img className="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" />
                                    </figure>
                                    <h3>Schnittstellen</h3>
                                    <p>
                                        Automatisierte Datenanlieferung gehört zum Tagesgeschäft. Binden Sie uns als Lieferant über verschiedene Schnittstellen an und kommen so näher an Industrie 4.0.
                                    </p>
                                    <ButtonLink to="/leistungen/schnittstellen">Mehr <span className="is-hidden-touch"> Informationen</span></ButtonLink>
                                </div>
                            </Card>
                        </div>
                        <div className="column">
                            <Card>
                            <div className="content">
                                    <figure className="image is-128x128">
                                        <img className="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" />
                                    </figure>
                                    <h3>Web to Print</h3>
                                    <p>
                                        Eigenes Produkt-Portfolie das über unser Web to Print Angebot individualisert werden kann senkt Aufwände und Kosten.
                                    </p>
                                    <ButtonLink to="/leistungen/web-to-print">Mehr Informationen</ButtonLink>
                                </div>
                            </Card>
                        </div>
                        <div className="column">
                            <Card>
                                <div className="content">
                                    <figure className="image is-128x128">
                                        <img className="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" />
                                    </figure>
                                    <h3>Webshop Systeme</h3>
                                    <p>
                                        Unser Shopsystem bietet eine Platform ein eigenes Produktportfolie direkt zu vertreiben. Herstellung und Wartung übernehmen wir. So versteht sich Service!
                                    </p>
                                    <ButtonLink to="/leistungen/webshop-systeme">Mehr Informationen</ButtonLink>
                                </div>
                                
                            </Card>
                        </div>
                    </div>
                </HorizontalScroll>
                

            </Section> */}

            <Questions />

            <FallbeispieleReferences
                    references={data.fallbeispiele.edges.map(node => node.node)}
                    title={"Fallbeispiele"}
                    
            />

        </Layout>
    );}

export const pageQuery = graphql`{
  header: file(relativePath: {regex: "/Leistungen-Header/"}) {
    relativePath
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  fallbeispiele: allFallbeispieleHJson(limit: 5, sort: {order: DESC, fields: published}) {
    edges {
      node {
        listenbild {
          alt
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        url
        name
        kurzbeschreibung
      }
    }
  }
}
`